import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Link } from 'gatsby'

const More = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulRichardWealeBlog(
        sort: { fields: publishedDate, order: DESC }
      ) {
        edges {
          node {
            title
            publishedDate
            snippet
            slug
          }
        }
      }
    }
  `)

  return (
    <div className="more-blog-section">
      <div className="more-blog-list">
        <h2>More From My Blog</h2>
        <ol>
          {data.allContentfulRichardWealeBlog.edges.map(edge => {
            return (
              <li>
                <Link to={`/blog/${edge.node.slug}`}>
                  <h2>{edge.node.title}</h2>
                </Link>
              </li>
            )
          })}
        </ol>
      </div>
    </div>
  )
}

export default More
