import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import '../styles/styles.scss'
import More from '../components/MoreBlog'

export const query = graphql`
  query($slug: String!) {
    contentfulRichardWealeBlog(slug: { eq: $slug }) {
      title
      copyrite
      body {
        json
      }
    }
  }
`

const Blog = props => {
  const { title, body, copyrite } = props.data.contentfulRichardWealeBlog

  return (
    <Layout>
      <div className="blog-post-main-wrap">
        <div className="blog-post-wrap">
          <section className="blog-content-wrap">
            <article>
              <h1>{title}</h1>
              {documentToReactComponents(body.json)}
              <p>{copyrite}</p>
            </article>
          </section>
        </div>

        <More />
      </div>
    </Layout>
  )
}

export default Blog
